<template>
<header class="header">
    <div class="container">
        <div class="header__inner">
            <div class="header__branding">
                <a class="header__logo-link" href="/">
                    <img src="~/assets/img/logo.svg" class="header__logo" />
                </a>
            </div>
            <nav class="header__nav" v-if="$route.name !== 'register'">
                <ul class="header__nav-list">
                    <li class="header__nav-item">
                        <router-link to="/kavels">Alle kavels</router-link>
                    </li>
                    <li class="header__nav-item">
                        <router-link to="/veilingen">Alle veilingen</router-link>
                    </li>
                    <li class="header__nav-item">
                        <router-link to="/categorieen">Categorieën</router-link>
                    </li>
                    <li class="header__nav-item">
                        <SearchForm />
                    </li>
                    <template v-if="$auth.user">
                        
                        <Tippy arrow :trigger="'click'" :interactive="true" class="cursor-pointer">
                            <li class="header__nav-item">
                                <div class="header__user">
                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 10C6.24333 10 4 7.75667 4 5C4 2.24333 6.24333 0 9 0C11.7567 0 14 2.24333 14 5C14 7.75667 11.7567 10 9 10ZM9 1.25C6.9325 1.25 5.25 2.9325 5.25 5C5.25 7.0675 6.9325 8.75 9 8.75C11.0675 8.75 12.75 7.0675 12.75 5C12.75 2.9325 11.0675 1.25 9 1.25Z" fill="#1C4C74"/>
                                    <path d="M17.125 20C16.78 20 16.5 19.72 16.5 19.375C16.5 15.2392 13.1358 11.875 9 11.875C4.86417 11.875 1.5 15.2392 1.5 19.375C1.5 19.72 1.22 20 0.875 20C0.53 20 0.25 19.72 0.25 19.375C0.25 14.55 4.175 10.625 9 10.625C13.825 10.625 17.75 14.55 17.75 19.375C17.75 19.72 17.47 20 17.125 20Z" fill="#1C4C74"/>
                                    </svg>
                                    <!-- <a-icon name="user" width="20" height="20" /> -->
                                </div>
                                <div>{{$auth.user['first-name']}}</div>
                            </li>

                            <template #content>
                            <div class="header__dropdown">
                                <ul class="dropdown">
                                    <li class="dropdown__item">
                                        <a href="/account">Account</a>
                                    </li>
                                    <li class="dropdown__item">
                                        <a href="/account/chat">Chat</a>
                                    </li>
                                    <!-- <li class="dropdown__item">
                                        <a href="/account">Klantenservice</a>
                                    </li> -->
                                    <!-- <li class="dropdown__item">
                                        <a href="/account">Over YourAuc</a>
                                    </li> -->
                                    <li class="dropdown__item">
                                        <a href="/">Mijn huidige biedingen</a>
                                    </li>
                                    <li class="dropdown__item">
                                        <a href="/">Mijn kavels</a>
                                    </li>
                                    <li class="dropdown__item">
                                        <a href="/logout">Uitloggen</a>
                                    </li>
                                </ul>
                            </div>
                            </template>
                        </Tippy>
                        <li class="header__nav-item header__nav-item--button" v-if="$route.name !== 'create-lot'">
                            <Tippy v-if="$auth.user.attributes['user-type'] == 'business'" arrow :trigger="'click'" :interactive="true" class="cursor-pointer">
                                <ABtn>
                                    <span class="header__nav-item-text">
                                        Start veiling of kavel
                                    </span>
                                    <span class="header__nav-item-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="16" height="16"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M232 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H184V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H392c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V72z"/></svg>
                                    </span>
                                </ABtn>
                                <template #content>
                                    <div class="header__dropdown">
                                        <ul class="dropdown">
                                            <li class="dropdown__item">
                                                <a href="/veiling-aanmaken">Zakelijke veiling starten</a>
                                            </li>
                                            <li class="dropdown__item">
                                                <a href="/kavel-aanmaken">Losse kavel veilen</a>
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </Tippy>
                            <NuxtLink v-else to="/kavel-aanmaken"><ABtn>Start kavel</ABtn></NuxtLink>
                        </li>
                    </template>
                    <template v-else>
                        <li class="header__nav-item" v-if="$route.name !== 'login'">
                            <NuxtLink to="/login"><ABtn>Log in</ABtn></NuxtLink>
                        </li>
                        <li class="header__nav-item">
                            <NuxtLink to="/registreer"><ABtn variant="outlined">Registreren</ABtn></NuxtLink>
                        </li>
                    </template>
                </ul>
            </nav>
            <nav class="header__mobile-nav">
                <li class="header__nav-item">
                    <NuxtLink to="/kavels">
                        Alle kavels
                    </NuxtLink>
                </li>
                <li class="header__nav-item">
                    <NuxtLink to="/veilingen">
                        Alle veilingen
                    </NuxtLink>
                </li>
            </nav>
            <!-- <nav class="header__mobile-nav">
                <li class="header__nav-item">
                    <NuxtLink to="/account">
                        <div class="header__user">
                            <a-icon name="user" width="20" height="20" />
                        </div>
                    </NuxtLink>
                </li>
                <div class="toggle-menu">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/></svg>
                </div>
            </nav> -->
        </div>
    </div>
</header>

</template>
<script>
import { Tippy } from 'vue-tippy'
import { defineComponent as dc } from '#imports'

export default dc({
  components: {
    Tippy
},
  setup() {
    const button = ref();
    const tooltip = ref();

    return { button, tooltip };
  }
})
</script>
<style lang="scss" scoped>
@import "@/assets/css/base/mixins";
.header {
    padding: 3rem 0;
    &__logo {
        height: 3rem;
        @include xxxl-down {
            height: 3.5rem;
        }
        @include xxl-down {
            height: 2.5rem;
        }
        @include xl-down {
            height: 2rem;
        }
        @include lg-down {
            height: 2rem;
        }
        @include md-down {
            height: 2rem;
        }
    }
    .container {
        max-width: unset;
    }
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__nav {
        color: var(--neutral-secondary);
        @include xxl-down {
            font-size: .875rem;
        }
        @include xl-down {
            display: none;
            visibility: hidden;
        }
    }
    &__mobile-nav {
        display: none;
        visibility: hidden;
        @include xl-down {
            display: flex;
            align-items: center;
            visibility: visible;
        }
        .toggle-menu {
            color: var(--neutral-secondary);
            svg {
                width: 2rem;
                height: 2rem;
                color: var(--neutral-secondary);
            }
        }
    }
    &__nav-list {
        display: flex;
        margin: 0 -.5rem;
        align-items: center;
    }
    &__nav-item {
        padding: 0 .5rem;
        font-weight: 700;
        display: inline-flex;
        align-items: center;
        color: var(--neutral-secondary);
        &--button {
            .header__nav-item-icon {
                display: none;
                // @include xxl-down {
                //     display: block;
                // }
            }
            .header__nav-item-text {
                // @include xxl-down {
                //     display: none;
                // }
            }
        }
    }
    &__user {
        background-color: #ffffff;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__dropdown {
        padding: 1rem;
        .dropdown {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: left;
            display: block;
            width: 100%;
            &__item {
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(245,246,250,.25);
                }
                a {
                    padding: .75rem 1rem .7rem 0;
                    display: block;
                    width: 100%;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
