<template>
<div class="search">
    <input class="search__input" type="text" v-model="searchVal" :placeholder="placeholder" :disabled="disabled" @keyup.enter="search" />
    <div class="search__action" @click="search"><font-awesome-icon icon="fas fa-search" /></div>
</div>
</template>
<script>
// import LotService from "@/services/lot.service"
export default {
    props: {
        placeholder: {
            type: String,
            default: 'Zoek in meer dan 20.000 kavels'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const router = useRouter();
        const searchVal = ref('');
        function search() {
            if(searchVal.value.length) {
                router.push({name: 'search', query: {'s': searchVal.value.toLowerCase()}}).catch(()=>{});
            }
        }
        return {
            search,
            searchVal
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/base/mixins';
.search {
    position: relative;
    &__input {
        padding: .5rem 3rem .5rem 1rem;
        margin: 0;
        border: 1px solid transparent;
        border-radius: 0;
        width: 100%;
        height: 2.5rem;
        box-sizing: border-box;
        background-color: #ffffff;
        &::placeholder {
            color: var(--color-neutral-secondary);
            font-size: .75rem;
        }
    }
    &__action {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 2.5rem;
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .b-icon-icon {
            color: var(--color-neutral-secondary);
            width: .875rem;
            height: .875rem;
            position: relative;
            transform: scale(1);
            transition: transform 200ms ease-in-out;
        }
        &:hover {
            cursor: pointer;
            .b-icon-icon {
                transform: scale(1.1);
                transition: transform 200ms ease-in-out;
            }
        }
    }
}
</style>