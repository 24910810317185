<template>
    <div class="toasts">
        <div class="toasts__list">
            <TransitionGroup name="toast">
                <div v-for="(toast, index) in toastStore.toasts" :key="index" class="toast" :class="{['toast--'+toast.type]: toast.type}">
                    {{ toast.text }}
                    <a v-if="toast.link" class="toast__link" :href="toast.link">Bekijk</a>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>
<script>
    import { useToastStore } from '@/stores/toastStore';
    export default {
        name: "ToastWrapper",
        props: {
            
        },
        setup() {
            const toastStore = useToastStore();
            return {
                toastStore
            }
        }
    }
</script>
<style lang="scss" scoped>
@import '@/assets/css/base/mixins';
.toasts {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 60000;

    &__list {
        display: flex;
        gap: 1rem;
        flex-direction: column-reverse;
        position: relative;
        .toast {
            padding: 1rem .75rem;
            display: flex;
            align-items: center;
            color: #ffffff;
            box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.44);
            -webkit-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.44);
            -moz-box-shadow: 0px 3px 18px 0px rgba(0,0,0,0.44);
            &--success {
                background-color: var(--status-success);
            }

            &--error {
                background-color: var(--status-danger);
            }

            &--info {
                background-color: var(--brand-neutral);
            }

            &--warning {
                background-color: var(#cd8e10);
            }
            &__link {
                font-weight: 800;
                padding: .5rem .5rem .5rem .75rem;
                opacity: .85;
            }
        }
        
    }
}
.toast-move, /* apply transition to moving elements */
.toast-enter-active,
.toast-leave-active {
  transition: all 0.5s ease;
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.toast-leave-active {
  position: absolute;
}
</style>