<template>
<div class="toolbar" :class="{'toolbar--menu-open': showMenu}">
    <Transition name="fade">
        <div v-if="showMenu" class="toolbar__mobile-menu">
            <ul class="toolbar__menu menu">
                <li class="menu__item">
                    <a href="/kavels">Alle kavels</a>
                </li>
                <li class="menu__item">
                    <a href="/veilingen">Alle veilingen</a>
                </li>
                <li class="menu__item">
                    <a href="/kavel-aanmaken">Kavel aanmaken</a>
                </li>
                <li class="menu__item">
                    <a href="/account">Account</a>
                </li>
                <li class="menu__item">
                    <a href="/categorieen">Categorieën</a>
                </li>
            </ul>
        </div>
    </Transition>
    <div class="toolbar__nav">
        <router-link to="/" class="toolbar__item">
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.254 21.9241C13.84 21.9241 13.504 21.5881 13.504 21.1741V15.1741C13.504 14.7601 13.168 14.4241 12.754 14.4241H11.254C10.84 14.4241 10.504 14.7601 10.504 15.1741V21.1741C10.504 21.5881 10.168 21.9241 9.75403 21.9241H3.75403C3.34003 21.9241 3.00403 21.5881 3.00403 21.1741V12.9241C3.00403 12.5101 3.34003 12.1741 3.75403 12.1741C4.16803 12.1741 4.50403 12.5101 4.50403 12.9241V20.4241H9.00403V15.1741C9.00403 13.9331 10.013 12.9241 11.254 12.9241H12.754C13.995 12.9241 15.004 13.9331 15.004 15.1741V20.4241H19.504V12.9241C19.504 12.5101 19.84 12.1741 20.254 12.1741C20.668 12.1741 21.004 12.5101 21.004 12.9241V21.1741C21.004 21.5881 20.668 21.9241 20.254 21.9241H14.254Z" fill="white"/>
            <path d="M0.754005 12.174C0.554005 12.174 0.365005 12.096 0.224005 11.954C-0.0679951 11.662 -0.0679951 11.186 0.224005 10.893L10.414 0.704021C10.838 0.280021 11.403 0.0460205 12.005 0.0460205C12.606 0.0460205 13.171 0.280021 13.596 0.704021L15.802 2.91002C15.909 2.62602 16.183 2.42302 16.504 2.42302H20.254C20.668 2.42302 21.004 2.75902 21.004 3.17302V8.11202L23.784 10.892C24.076 11.184 24.076 11.66 23.784 11.953C23.642 12.095 23.454 12.173 23.254 12.173C23.054 12.173 22.865 12.095 22.724 11.953L12.535 1.76602C12.394 1.62502 12.206 1.54702 12.005 1.54702C11.804 1.54702 11.616 1.62502 11.474 1.76602L1.284 11.955C1.143 12.096 0.954005 12.174 0.754005 12.174ZM19.504 6.61402V3.92402H17.254V4.36302L19.504 6.61402Z" fill="white"/>
            </svg>
        </router-link>
        <router-link to="/kavels" class="toolbar__item">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.245 23.996C23.045 23.996 22.856 23.918 22.715 23.776L16.2 17.26C15.439 17.911 14.582 18.442 13.647 18.839C12.418 19.361 11.127 19.626 9.81001 19.626C8.55301 19.626 7.31801 19.385 6.13701 18.908C3.70601 17.927 1.80301 16.059 0.778008 13.646C-0.246992 11.234 -0.271992 8.56599 0.709008 6.13599C1.69001 3.70599 3.55801 1.80199 5.97001 0.77699C7.19901 0.25399 8.48901 -0.0100098 9.80701 -0.0100098C11.064 -0.0100098 12.299 0.23199 13.48 0.70799C15.911 1.68899 17.814 3.55699 18.839 5.96999C19.864 8.38299 19.889 11.05 18.908 13.48C18.506 14.476 17.952 15.389 17.259 16.198L23.776 22.716C24.068 23.008 24.068 23.484 23.776 23.777C23.634 23.918 23.445 23.996 23.245 23.996ZM9.80701 1.48999C8.69201 1.48999 7.59801 1.71399 6.55701 2.15699C4.51301 3.02599 2.93001 4.63799 2.09901 6.69699C1.26801 8.75599 1.28901 11.015 2.15701 13.059C3.02601 15.103 4.63801 16.686 6.69701 17.517C7.69801 17.921 8.74501 18.125 9.80901 18.125C10.924 18.125 12.018 17.901 13.059 17.458C14.033 17.044 14.906 16.46 15.653 15.722C15.663 15.708 15.674 15.696 15.685 15.685C15.701 15.669 15.716 15.656 15.73 15.646C16.493 14.875 17.099 13.953 17.516 12.918C18.347 10.859 18.326 8.59999 17.457 6.55599C16.589 4.51199 14.976 2.92899 12.917 2.09799C11.918 1.69499 10.871 1.48999 9.80701 1.48999Z" fill="white"/>
            </svg>
        </router-link>
    </div>
    <router-link to="/kavel-aanmaken" class="toolbar__main-btn">
        <svg class="toolbar__add-lot" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 23C15.448 23 15 22.552 15 22V17H10C9.448 17 9 16.552 9 16C9 15.448 9.448 15 10 15H15V10C15 9.448 15.448 9 16 9C16.552 9 17 9.448 17 10V15H22C22.552 15 23 15.448 23 16C23 16.552 22.552 17 22 17H17V22C17 22.552 16.552 23 16 23Z" fill="#1C4C74"/>
            <path d="M16 32C7.17733 32 0 24.8227 0 16C0 7.17733 7.17733 0 16 0C24.8227 0 32 7.17733 32 16C32 24.8227 24.8227 32 16 32ZM16 2C8.28 2 2 8.28 2 16C2 23.72 8.28 30 16 30C23.72 30 30 23.72 30 16C30 8.28 23.72 2 16 2Z" fill="#1C4C74"/>
        </svg>
        <svg width="153" height="60" viewBox="0 0 153 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_145_2358)">
        <path d="M42.015 38C49.275 23.0769 60.4675 12 76.5 12C92.5325 12 103.423 23.6923 110.985 38C116.43 48.3077 121.724 56.1538 132.16 59.3846C133.068 59.6923 135.185 60 137 60L16 60C17.21 60 18.5713 59.6923 19.63 59.3846C29.7638 56.1538 35.965 50.6154 42.015 38Z" fill="#F5F6FA"/>
        </g>
        <defs>
        <filter id="filter0_d_145_2358" x="0" y="0" width="153" height="80" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="8"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_145_2358"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_145_2358" result="shape"/>
        </filter>
        </defs>
        </svg>
    </router-link>
    <div class="toolbar__nav">
        <router-link to="/account" class="toolbar__item">
            <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 12C7.692 12 5 9.308 5 6C5 2.692 7.692 0 11 0C14.308 0 17 2.692 17 6C17 9.308 14.308 12 11 12ZM11 1.5C8.519 1.5 6.5 3.519 6.5 6C6.5 8.481 8.519 10.5 11 10.5C13.481 10.5 15.5 8.481 15.5 6C15.5 3.519 13.481 1.5 11 1.5Z" fill="white"/>
            <path d="M20.75 24C20.336 24 20 23.664 20 23.25C20 18.287 15.963 14.25 11 14.25C6.037 14.25 2 18.287 2 23.25C2 23.664 1.664 24 1.25 24C0.836 24 0.5 23.664 0.5 23.25C0.5 17.46 5.21 12.75 11 12.75C16.79 12.75 21.5 17.46 21.5 23.25C21.5 23.664 21.164 24 20.75 24Z" fill="white"/>
            </svg>
        </router-link>
        <div class="toolbar__item toolbar__item--no-fade" @click="showMenu = !showMenu">
            <template v-if="showMenu">
                <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>            </template>
            <template v-else>
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.25 13.7531C0.836 13.7531 0.5 13.4171 0.5 13.0031C0.5 12.5891 0.836 12.2531 1.25 12.2531H20.75C21.164 12.2531 21.5 12.5891 21.5 13.0031C21.5 13.4171 21.164 13.7531 20.75 13.7531H1.25Z" fill="white"/>
                <path d="M1.25 7.75305C0.836 7.75305 0.5 7.41705 0.5 7.00305C0.5 6.58905 0.836 6.25305 1.25 6.25305H20.75C21.164 6.25305 21.5 6.58905 21.5 7.00305C21.5 7.41705 21.164 7.75305 20.75 7.75305H1.25Z" fill="white"/>
                <path d="M1.25 1.75305C0.836 1.75305 0.5 1.41705 0.5 1.00305C0.5 0.589052 0.836 0.253052 1.25 0.253052H20.75C21.164 0.253052 21.5 0.589052 21.5 1.00305C21.5 1.41705 21.164 1.75305 20.75 1.75305H1.25Z" fill="white"/>
                </svg>
            </template>
        </div>
    </div>
</div>
</template>
<script>
export default {
    components: {
    
    },
    setup(props, { emit }) {
        // eslint-disable-next-line no-undef
        const showMenu = ref(false);

        return {
            showMenu
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/base/mixins";
.toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--neutral-tertiairy);
    width: 100%;
    color: #ffffff;
    display: none;
    visibility: hidden;
    z-index: 20000;
    @include xl-down {
        display: flex;
        visibility: visible;
    }
    // @include xl-down {
    //     display: flex;
    //     visibility: visible;
    //     color: red;
    // }
    &--menu-open {
        .toolbar__item, .toolbar__main-btn {
            opacity: .1;
            pointer-events: none;
            &--no-fade {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
    &__nav {
        display: flex;
        flex-grow: 1;
        padding: 1rem 0;
    }
    &__item {
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        display: flex;
        transition: opacity 150ms ease-in-out;
    }
    &__main-btn {
        transition: opacity 150ms ease-in-out;
    }
    &__add-lot {
        position: absolute;
        left: calc(50% - 1rem);
        top: calc(50% - .75rem);
    }

    &__mobile-menu {
        height: 100vh;
        width: 100%;
        position: absolute;
        top: calc(-100vh + 3.75rem);
        background-color: var(--neutral-tertiairy);
        z-index: -1;
        transform: scale(1);
        opacity: 1;
        padding: 2rem;
        .menu {
            list-style: none;
            margin: 0;
            padding: 0;
            &__item {
                width: 100%;
                font-size: 1.125rem;
                a {
                    padding: 1rem 0;
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}
</style>
